import React, { useEffect, useState } from 'react'


import {
  Accordion,
  Button,
  Card,
  Container,
  Form,
  FormControl,
  ListGroup,
  Nav,
  Navbar,
  NavDropdown,
  NavLink,
  Offcanvas,
} from "react-bootstrap";
import { BsSpeedometer, BsFillPeopleFill } from 'react-icons/bs'
import { BiBlock, BiStore } from 'react-icons/bi'
import { VscWorkspaceTrusted } from 'react-icons/vsc'

import { Navigate, Navigation, useNavigate } from "react-router-dom";
import {
  AiFillAppstore,
  AiFillDatabase,
  AiOutlineRise,
  AiOutlineAppstoreAdd,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { GiKnightBanner } from 'react-icons/gi'
// import Contactlist from "../contact/Contactlist";
import logo from "../../assets/images/logo.png";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import './index.css'
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import baseURL from "../../Services/Url";
import { AgGridReact } from "ag-grid-react";
import logozelt from '../../assets/imagesCustomer/logozelt.png'
import {  useLocation } from "react-router-dom";



function SideBar({ Navigation }) {
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false)
  const [counter, setCounter] = useState(0)
  const [liveRate, setLiveRate] = useState("");

  const handleClick = (e) => {

    // console.log(e);
    Navigation.navigate(`/list_out`, {
      data: e
    })
  }
  const liveRateGet = async () => {
    try {
      const response = await axios({
        method: "GET",
        url: "https://zelt-cart.moshimoshi.cloud/cart/live-rate",
      });

      console.log("live rate data", response?.data);
      setLiveRate(response?.data?.data["22kt"]);

      //else {}
    } catch (error) {
      console.log("live rate error ====> ", error);
    }
  };
  useEffect(()=>{
    liveRateGet()
  },[])
  // let navigate = useNavigate();
  // const [reloadPage, setReloadPage] = useState(1);
  // const [rowData, setRowdata] = useState([]);
  // useEffect(() => {
  //   axios({
  //     mathod: "GET",
  //     url: `${baseURL}/category`,
  //   }).then((res) => {
  //     if (res.status == 200) {
  //       // console.log(res.data);
  //       setRowdata(res.data)
  //       // console.log(res.data);
  //     }
  //   }).catch((err) => {
  //     console.log(err, 'err');
  //   })
  // }, [reloadPage])

  // // const userType = 'Admin';
  const userType = '';

  const AdminScreens = [{
    path: '/dashboard',
    name: 'Dashboard',
    icon: <BsSpeedometer className="icons" />
  },
  {
    path: '/customer',
    name: 'Customer',
    icon: <BsFillPeopleFill className="icons" />

  },
  {
    path: '/vendor',
    name: 'Vendor',
    icon: <BiStore className="icons" />

  },
  // {
  //   path: '/addvendor',
  //   name: 'Add Vendor',
  //   icon: <AiOutlineAppstoreAdd className="icons" />

  // },
  // {
  //   path: '/vendorRequest',
  //   name: 'Vendor Request',
  //   icon: <AiOutlineAppstoreAdd className="icons" />


  // },
  {
    path: '/Request',
    name: 'All Request',
    icon: <GiKnightBanner className="icons" />

  },
  {
    path: '/BlockedList',
    name: 'Blocked List',
    icon: <BiBlock className="icons" />

  },
  {
    path: '/TermsConditions',
    name: 'Terms & Conditions',
    icon: <VscWorkspaceTrusted className="icons" />

  },
  ]

  // const VendorScreens = [{
  //   path: '/vendorDashboard',
  //   name: 'Vendor Dashboard'
  // },
  // {
  //   path: '/vendorProfile',
  //   name: 'Profile'
  // },
  // {
  //   path: '/vendorStore',
  //   name: 'My Store'
  // },
  // {
  //   path: '/vendorAdmin',
  //   name: 'Admin'
  // },
  // {
  //   path: '/vendorProducts',
  //   name: 'Physical Jewellery'
  // },
  // {
  //   path: '/vendorScheme',
  //   name: ' Scheme'
  // },
  // {
  //   path: '/vendorStats',
  //   name: 'Stats'
  // },
  // {
  //   path: '/vendorBrochure',
  //   name: 'Brochure'
  // },
  // ]

  return (
    <div>
      <div className="d-inline-flex px-2 pt-2 gap-2">
        <img src={logozelt} width="25%" height="20%" className="m-0" alt='logo' />
        <h1>Zelt</h1>
      </div>
      <div>
        {userType === '' && //userType=== 'Admin'
          <>
            {AdminScreens.map((nav, i) => {
              return (
                <p key={i} className={`cursor fs-6 pt-2 pb-1 px-2 m-0 ${location.pathname == nav.path ? 'active' : ''} `}>
                  <Link to={nav?.path} className="p-1">
                    {nav?.icon} {nav?.name}
                  </Link>
                </p>
              )
            })}
          </>
        }

      </div>
      <div className='d-flex justify-content-center mt-5 align-content-center' style={{ backgroundColor: '#d9d6a9' }}>
        <div className='mt-2'>
          <h1 className='fs-6 fw-bold w-100 m-1'>Live Rate</h1>
          <p className='fw-bold text-danger w-100 '>{liveRate}</p>
        </div>
      </div>
    </div>
  );
}
// }

export default SideBar;
