import React, { useState, useEffect } from 'react';
import { Card, Form, Row, Col, Button, Modal } from 'react-bootstrap'; // Import Modal component
// ... other imports
import SideBar from '../../dashboard/SideBar'
import FirstNavbar from '../../dashboard/FirstNavbar'
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import AuthServices from '../authServices/AuthServices';

// const earRing = require('../../../assets/imagesCustomer/earRing1.png')
// const image = require('../../../assets/imagesCustomer/image18.png')

function VendorList() {
    const [showModal, setShowModal] = useState(false);
    const [vendorData, setVendorData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        phone: '',
        role: 'basic',
        type: 'vendor',
    });

    const [vendorDataError, setVendorDataError] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        phone: '',
    });

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        // Clear the form data and errors when closing the modal
        setVendorData({
            name: '',
            email: '',
            password: '',
            confirmPassword: '',
            phone: '',
            role: 'basic',
            type: 'vendor',
        });
        setVendorDataError({
            name: '',
            email: '',
            password: '',
            confirmPassword: '',
            phone: '',
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setVendorData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleChangePhone = (e) => {
        const { name, value } = e.target;
        const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters

        // Check if the numericValue is 10 digits or less
        if (numericValue.length <= 10) {
            setVendorData((prevData) => ({
                ...prevData,
                [name]: numericValue,
            }));
        }
    };


    const handleAddVendor = () => {

        console.log("i am in pass");

        // Validate form data
        if (!vendorData.email || !vendorData.password || !vendorData.confirmPassword || !vendorData.phone) {
            setVendorDataError({
                ...vendorDataError,
                name: !vendorData.name ? 'Name is Require' : '',
                email: !vendorData.email ? 'Email is required' : '',
                password: !vendorData.password ? 'Password is required' : '',
                confirmPassword: !vendorData.confirmPassword ? 'Confirm Password is required' : '',
                phone: !vendorData.phone ? 'Phone is required' : '',
            });
            return;
        }

        if (vendorData.password !== vendorData.confirmPassword) {
            setVendorDataError({
                ...vendorDataError,
                confirmPassword: 'Passwords do not match',
            });
            console.log("i am in pass");
            return;
        }

        // If validation passes, send the data to the backend
        const requestData = {
            name: vendorData.name,
            email: vendorData.email,
            phone: vendorData.phone,
            password: vendorData.password,
            role: vendorData.role,
            type: vendorData.type,
        };

        const token = localStorage.getItem('accessToken');

        // Set the headers with the token
        const headers = {
            "x-access-token": token,
        };

        // Send a POST request to the backend API with headers
        axios.post('https://zelt-admin-api.moshimoshi.cloud/user/vendor', requestData, {
            headers: headers,
        })
            .then((response) => {
                console.log('Vendor added successfully:', response.data);
                closeModal(); // Close the modal after adding a vendor
                // Optionally, you can perform additional actions or show a success message
            })
            .catch((error) => {
                console.error('Error adding vendor:', error);
                // Handle any errors or show an error message
            });
    };





    const [selection, setSelection] = useState('1')
    const navigate = useNavigate();

    const onDataSelect = (e) => {
        setSelection(e.target.value)
    }

    const [rowData, setRowData] = useState([]);

    const rowHeight = 50;
    const DefaultColumnSetting = {
        sortable: true,
        //   filter: true,
        //   floatingFilter: true,
        flex: 1,
        resizable: true,
        minWidth: 120,
    };

    const [colDefs, setColDefs] = useState([
        {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            // filter: true,
            lockPosition: true,
        },
        {
            headerName: "Vendor Name",
            // filter: true,
            cellRendererFramework: (params) => (
                <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate('/vendorData', { state: params.data })}
                >
                    {params.data.name}
                </div>
            ),
        },
        {
            headerName: "phone",
            // filter: true,
            field: "phone",
        },
        {
            headerName: "E-mail Id ",
            // filter: true,
            field: "email",
        },
        {
            headerName: "Stores",
            // filter: true,
            // field: "view",
            cellRendererFramework: (params) => (
                <>
                    <div>  </div>
                </>
            ),
        },
        // {
        //     headerName: "Block",
        //     // filter: true,
        //     // field: "approve",
        //     cellRendererFramework: (params) => (
        //         <center>
        //             <div>
        //                 <Button onClick={() => declineVendor(params.data)} variant="outline-danger">Block</Button>
        //             </div>
        //         </center>
        //     ),
        // },
    ])


    useEffect(() => {
        vendorDataList()
    }, []);

    const vendorDataList = () => {
        let token = localStorage.getItem('accessToken');
        axios.get("https://zelt-admin-api.moshimoshi.cloud/user/all?type=vendor", {
            headers: {
                "x-access-token": token
            }
        }).then((response) => {
            console.log(response.data.data, "RESPONSE DATA")
            setRowData(response.data.data)
            if (response.data.error == false) {

            }
        });
    }

    const vendorDataList1 = () => {
        let token = localStorage.getItem('accessToken');
        axios.get("https://zelt-admin-api.moshimoshi.cloud/user/approveList", {
            headers: {
                "x-access-token": token
            }
        }).then((response) => {
            console.log(response.data.data, "RESPONSE DATA")
            setRowData(response.data.data)
            if (response.data.error == false) {

            }
        });
    }

    const declineVendor = async (data) => {
        try {
            const payload = {
                _id: data?._id,
                status: 'blocked',
            }
            const disApproveVendor = await AuthServices.patchAdmin('/user/approveOrBlockVendor', payload)
            console.log('disApproveVendor', disApproveVendor);
            if (disApproveVendor?.error === false) {
                vendorDataList()
            }
        } catch (e) {
            console.log('error', e);
        }
    }


    return (
        <div>
            <div class="sidebar">
                <SideBar />
            </div>
            <div class="content">
                <div className="container">
                    <FirstNavbar />
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className='py-1'>
                        <h3 className='headertext'>Vendor Data</h3>
                        <input class="btn btn-primary" type="button" value="Add Vendor" onClick={openModal} />
                    </div>

                    <div
                        className="ag-theme-alpine"
                        style={{ height: "70vh", width: "100%" }}
                    >
                        <AgGridReact
                            rowHeight={rowHeight}
                            // columnDefs={columns}
                            columnDefs={colDefs}
                            defaultColDef={DefaultColumnSetting}
                            pagination={true}
                            paginationPageSize={10}
                            // onGridReady={onGridReady}
                            rowData={rowData}
                        // onRowClicked={(e) => 
                        //     navigate('/vendorData',{id:e.data._id})}
                        />
                    </div>
                    {/* Add the modal */}
                    <Modal show={showModal} onHide={closeModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Vendor</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>

                                <Form.Group controlId="formVendorName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter vendor name"
                                        name='name'
                                        value={vendorData.name}
                                        onChange={handleChange}
                                    />
                                    <span className="text-danger">{vendorDataError.name}</span>
                                </Form.Group>
                                <Form.Group controlId="formVendorEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter vendor email"
                                        name='email'
                                        value={vendorData.email}
                                        onChange={handleChange}
                                    />
                                    <span className="text-danger">{vendorDataError.email}</span>
                                </Form.Group>
                                <Form.Group controlId="formVendorPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Enter password"
                                        name="password"
                                        value={vendorData.password}
                                        onChange={handleChange}
                                    />
                                    <span className="text-danger">{vendorDataError.password}</span>
                                </Form.Group>
                                <Form.Group controlId="formConfirmPassword">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Confirm password"
                                        name="confirmPassword"
                                        value={vendorData.confirmPassword}
                                        onChange={handleChange}
                                    />
                                    <span className="text-danger">{vendorDataError.confirmPassword}</span>
                                </Form.Group>
                                <Form.Group controlId="formVendorPhone">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter vendor phone"
                                        value={vendorData.phone}
                                        name="phone"
                                        onChange={handleChangePhone}
                                    />
                                    <span className="text-danger">{vendorDataError.phone}</span>

                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={closeModal}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={handleAddVendor}>
                                Add
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {/* <Button variant="warning" onClick={() => navigate('/vendorData')}>Next</Button> */}
                </div>
            </div>
        </div>
    )
}

export default VendorList