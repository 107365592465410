import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/auth/Login";
import "bootstrap/dist/css/bootstrap.min.css";
import "./custom.css";
import "bootstrap/dist/css/bootstrap.min.css";
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import DashboardAdmin from "./pages/adminPanel/dashboard/DashboardAdmin";
import CustomerList from "./pages/adminPanel/customer/CustomerList";
import VendorData from "./pages/adminPanel/vendor/VendorData";
import CustomerData from "./pages/adminPanel/customer/CustomerData";
import VendorList from "./pages/adminPanel/vendor/VendorList";
import ProtectedRoute from './pages/Routers/ProtectedRoute';
import VendorRequest from "./pages/adminPanel/requests/VendorRequest";
import VendorDetail from "./pages/adminPanel/requests/VendorDetail";
import BannerRequest from "./pages/adminPanel/requests/banner/BannerRequest";
import Request from "./pages/adminPanel/requests/Request";
import BlockedList from "./pages/adminPanel/blockedList/BlockedList";
import TermsConditions from "./pages/adminPanel/termsConditions/TermsConditions";
import AdvertisementBlocked from "./pages/adminPanel/requests/advertisement/AdvertisementBlocked";
import AdvertisementActive from "./pages/adminPanel/requests/advertisement/AdvertisementActive";
import AdvertisementRequest from "./pages/adminPanel/requests/advertisement/AdvertisementRequest";
import Advertisements from "./pages/adminPanel/requests/advertisement/Advertisements";
import AddVendor from "./pages/adminPanel/vendor/AddVendor";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <DashboardAdmin />
            </ProtectedRoute>
          }
        />
        <Route
          path="/customer"
          element={
            <ProtectedRoute>
              <CustomerList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vendorData"
          element={
            <ProtectedRoute>
              <VendorData />
            </ProtectedRoute>
          }
        />
        <Route
          path="/customerData"
          element={
            <ProtectedRoute>
              <CustomerData />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vendor"
          element={
            <ProtectedRoute>
              <VendorList />
            </ProtectedRoute>
          }
        />
         <Route
          path="/vendorRequest"
          element={
            <ProtectedRoute>
              <VendorRequest />
            </ProtectedRoute>
          }
        />
         <Route
          path="/VendorDetail"
          element={
            <ProtectedRoute>
              <VendorDetail />
            </ProtectedRoute>
          }
        />
         <Route
          path="/bannerRequest"
          element={
            <ProtectedRoute>
              <BannerRequest />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Request"
          element={
            <ProtectedRoute>
              <Request />
            </ProtectedRoute>
          }
        />
         <Route
          path="/BlockedList"
          element={
            <ProtectedRoute>
              <BlockedList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/TermsConditions"
          element={
            <ProtectedRoute>
              <TermsConditions />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AdvertisementRequest"
          element={
            <ProtectedRoute>
              <AdvertisementRequest />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AdvertisementBlocked"
          element={
            <ProtectedRoute>
              <AdvertisementBlocked />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AdvertisementActive"
          element={
            <ProtectedRoute>
              <AdvertisementActive />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Advertisements"
          element={
            <ProtectedRoute>
              <Advertisements />
            </ProtectedRoute>
          }
        />
        <Route
          path="/addvendor"
          element={
            <ProtectedRoute>
              <AddVendor />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default App
