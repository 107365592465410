import React, { useState } from 'react'
import SideBar from '../../dashboard/SideBar'
import FirstNavbar from '../../dashboard/FirstNavbar'
import { Card, Row, Col, Figure, Table, Button, Modal, Form } from 'react-bootstrap'
import Plot from 'react-plotly.js'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import AuthServices from '../authServices/AuthServices'


const image = require('../../../assets/imagesCustomer/image.png');

function VendorDetail() {

    const navigate = useNavigate();
    const { state } = useLocation();
    console.log('state', state)

    const [docmodal, setDocModal] = useState(false)
    const [selection, setSelection] = useState('1')
    const [selectedShop, setSelectedShop] = useState()

    const handleCloseModal = () => setDocModal(false)
    const handleShowModal = () => {
        // setSelectedShop(data)
        setDocModal(true)
    }

    const onGoldSelect = (e) => {
        setSelection(e.target.value)
    }

    const acceptVendor = async (data) => {
        try {
            console.log('ddddddddddata', data);
            console.log('ddddddddddataaaaaaa', data?._id);

            const payload = {
                _id: state?._id,
                status: 'approved',
            }
            const approveVendor = await AuthServices.patchAdmin('/user/approveOrBlockVendor', payload)
            console.log('approveVendor', approveVendor);
            if (approveVendor.error === false) {
                navigate('/vendorRequest')
                // vendorRequestList1()
            }
        } catch (e) {
            console.log('error', e);
        }
    }

    const declineVendor = async (data) => {
        try {
            const payload = {
                _id: state?._id,
                status: 'blocked',
            }
            const disApproveVendor = await AuthServices.patchAdmin('/user/approveOrBlockVendor', payload)
            console.log('disApproveVendor', disApproveVendor);
            if(disApproveVendor?.error === false){
                navigate('/vendorRequest')
                // vendorRequestList1()
            }
        } catch (e) {
            console.log('error', e);
        }
    }

    return (
        <div>
            <div class="sidebar">
                <SideBar />
            </div>
            <div class="content">
                <div className="container">
                    <FirstNavbar />
                    <h3 className='headertext'>Vendor Details:</h3>
                    <div>
                        <Card className='p-2'>
                            <Row>
                                <Col md={2}>
                                    <p>Name:</p>
                                    {/* <p>Shop Name</p>
                                    <p>Phone Number:</p>
                                    <p>Email:</p>
                                    <p>Address:</p> */}
                                </Col>
                                <Col md={3}>
                                    <p>{state?.name || '-'}</p>
                                    {/* <p>{state?.shops[0]?.name || ''}</p>
                                    <p>+91 {state?.phone}</p>
                                    <p>rahul@gmail.com</p>
                                    <p>Cecilia Chapman
                                        711-2880 Nulla St.
                                        Mankato Mississippi 96522
                                        (257) 563-7401</p> */}

                                </Col>
                            </Row>
                            <Row>
                                <Col md={2}>
                                    <p>Shop Name:</p>
                                </Col>
                                <Col md={3}>
                                    <p>{state?.shops[0]?.name || '-'}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2}>
                                    <p>Phone Number:</p>
                                </Col>
                                <Col md={3}>
                                    <p>+91 {state?.phone || '-'}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2}>
                                    <p>Email:</p>
                                </Col>
                                <Col md={3}>
                                    <p>{state?.email || '-'}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2}>
                                    <p>Address:</p>
                                </Col>
                                <Col md={3}>
                                    <p>{state?.address[0] || '-'}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2}>
                                    <p>GST Number:</p>
                                </Col>
                                <Col md={3}>
                                    <p>{state?.shops[0]?.gst || '-'}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2}>
                                    <p>GST Image:</p>
                                </Col>
                                <Col md={2}>
                                    <Figure>
                                        <Figure.Image
                                            width={100}
                                            height={100}
                                            // alt="171x180"
                                            src={state?.shops[0]?.gstImage}
                                        />
                                    </Figure>
                                </Col>
                                <Col md={3}>
                                    <Button onClick={() => handleShowModal()} variant="outline-warning">View GST Document</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2}>
                                    <Button onClick={() => acceptVendor()} variant="warning">Approve</Button>
                                </Col>
                                <Col md={2}>
                                    <Button onClick={() => declineVendor()} variant="danger">UnApprove</Button>
                                </Col>
                                <Col md={2}>
                                    <Button onClick={() => navigate('/vendorRequest')} variant="secondary">cancel</Button>
                                </Col>
                            </Row>
                            <hr />

                        </Card>
                    </div>
                </div>
            </div>
            <Modal
                show={docmodal}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                centered
                size="lg"
            >
                <h4 className='headertext text-center'>GST Document:</h4>
                <Modal.Body>
                    <Row>
                    { state?.shops[0]?.gstImage ? 
                        <Figure>
                            <Figure.Image
                                width='100%'
                                height={600}
                                // alt="171x180"
                                src={state?.shops[0]?.gstImage}
                            />
                        </Figure> : 
                        <div>
                            <p>No Gst Image</p>
                        </div>}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModal} variant="secondary">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default VendorDetail