import React, { useState, useEffect } from 'react'
import SideBar from '../../../dashboard/SideBar'
import FirstNavbar from '../../../dashboard/FirstNavbar'
import { Card, Form, Row, Col, Button, Modal, Figure } from 'react-bootstrap'
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import AuthServices from '../../authServices/AuthServices';
import AdvertisementActive from './AdvertisementActive';
import AdvertisementBlocked from './AdvertisementBlocked';
import AdvertisementRequest from './AdvertisementRequest';

const image = require('../../../../assets/imagesCustomer/image.png');

function Advertisements() {
    const [selection, setSelection] = useState('1')

    const onDataSelect = (e) => {
        setSelection(e.target.value)
    }

    const navigate = useNavigate();

    return (
        <div>
            <div>
                <Card className="shadow rounded p-2 w-100">
                    <Row>
                        <Col md={3}>
                            <div className="leftedge d-flex justify-content-space">
                                <Form.Select
                                    aria-label="Default select example"
                                    size="sm"
                                    onChange={onDataSelect}
                                >
                                    {/* <option>Please select Request:</option> */}
                                    <option value="1">Advertisement Request</option>
                                    <option value="2">Advertisement Active</option>
                                    <option value="3">Advertisement Blocked</option>
                                </Form.Select>

                            </div>
                        </Col>
                    </Row>
                    <hr />
                    {selection === '1' && <AdvertisementRequest />}
                    {selection === '2' && <AdvertisementActive />}
                    {selection === '3' && <AdvertisementBlocked />}
                </Card>

            </div>
        </div>

    )
}

export default Advertisements