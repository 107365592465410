import React from 'react'
import { Card, Figure, Row, Col, Table } from 'react-bootstrap'

function SchemeList({ vendor, selectedShop }) {
  const shopData = vendor?.shops?.find((x) => x?._id === selectedShop)

  return (
    <div>
      <Card className='p-2'>
        {/* <h3 className='headertext'>Schemes Customer Invested:</h3>
        <Row>
          <Col md={3}>
            <h6>Total Customer Investment :</h6>
          </Col>
          <Col md={2}>
            <h6> Rs :20,2000 /-</h6>
          </Col>
          <Col md={3}>
            <h6>3.2 in gms</h6>
          </Col>
        </Row>
        <hr /> */}
        <h4 className='headertext'>Schemes List:</h4>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Scheme Name</th>
              <th>Tenure</th>
              <th>Amount Invested</th>
              <th>Discount</th>
            </tr>
          </thead>
          <tbody>
            {shopData?.schemes?.map((scheme,i) => {
              return (
                <tr key={i}>
                  <td>{i+1}</td>
                  <td>{scheme?.name}</td>
                  <td>({scheme?.duration?.customerTime} + {scheme?.duration?.vendorTime})</td>
                  <td>{scheme?.minAmt}</td>
                  <td>{scheme?.discount} %</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Card>
    </div>
  )
}

export default SchemeList