import React from 'react'
import { Card, Figure, Row, Col, Table } from 'react-bootstrap'


function JewelleryPurchased() {
  return (
    <div>
      <Card className='p-2'>
        <h3 className='headertext'>Jewellery Purchased :</h3>
        <Row>
          <Col md={3}>
            <h6>Total No. of Jewellery Purchased : </h6>
          </Col>
          <Col md={2}>
            <h6> 10</h6>
          </Col>
        </Row>
        <hr />
        <h4>Jewellery Purchased by Customer :</h4>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Jewellery Name</th>
              <th>Jewellery Image</th>
              <th>Shop Name</th>
              <th>Price</th>
              <th>weight</th>
              <th>category</th>
              <th>metal</th>
              <th>purity</th>

            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              
              <td>ring</td>
              <td>sri vinayaka jewellers</td>
              <td>6000</td>
              <td>1.5 gms</td>
              <td>ring</td>
              <td>gold</td>
              <td>24</td>
            </tr>
            <tr>
              <td>2</td>
              <td>ring</td>
              <td>sri vinayaka jewellers</td>
              <td>6000</td>
              <td>1.5 gms</td>
              <td>ring</td>
              <td>gold</td>
              <td>24</td>
            </tr>
            <tr>
              <td>3</td>
              <td>ring</td>
              <td>sri vinayaka jewellers</td>
              <td>6000</td>
              <td>1.5 gms</td>
              <td>ring</td>
              <td>gold</td>
              <td>24</td>
            </tr>
          </tbody>
        </Table>
      </Card>
    </div>
  )
}

export default JewelleryPurchased