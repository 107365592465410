import React, { useState, useEffect } from 'react'
import SideBar from '../../../dashboard/SideBar'
import FirstNavbar from '../../../dashboard/FirstNavbar'
import { Card, Form, Row, Col, Button, Modal, Figure } from 'react-bootstrap'
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import AuthServices from '../../authServices/AuthServices';

const image = require('../../../../assets/imagesCustomer/image.png');

function ProductRequest() {

    const navigate = useNavigate();

    const [docmodal, setDocModal] = useState(false)
    const [prodSelected, setProductSelected] = useState()
    const handleCloseModal = () => setDocModal(false)
    const handleShowModal = (data) => {
        console.log('setProductSelected', data);
        setProductSelected(data)
        setDocModal(true)
    }

    const rowHeight = 50;
    const DefaultColumnSetting = {
        sortable: true,
        //   filter: true,

        //   floatingFilter: true,
        flex: 1,
        resizable: true,
        minWidth: 120,
    };

    const [colDefs, setColDefs] = useState([
        {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            // filter: true,
            lockPosition: true,
        },
        {
            headerName: "Jewellery Image",
            // filter: true,
            field: "jewellery",
            cellRendererFramework: (params) => (
                <center>
                    <Figure>
                    <Figure.Image
                    width={150}
                    height={300}
                    // alt="171x180"
                    src={params?.data?.image[0]}
                  />
                    </Figure>
                </center>
            ),
        },
        {
            headerName: "Shop Name",
            // filter: true,
            field: "shop",
            valueGetter: 'data?.shops[0]?.name'
        },
        {
            headerName: "metal",
            // filter: true,
            field: "metal",
            valueGetter: 'data?.metal'
        },
        {
            headerName: "Weight",
            // filter: true,
            field: "weight",
            valueGetter: 'data?.weight'
        },
        // {
        //     headerName: "Date of Request",
        //     // filter: true,
        //     field: "date",
        // },
        {
            headerName: "View",
            // filter: true,
            // field: "view",
            cellRendererFramework: (params) => (
                <center>
                    <FontAwesomeIcon onClick={() => handleShowModal(params?.data)} className="editIcon" icon={faEye}> </FontAwesomeIcon>{" "}
                </center>
            ),
        },
        {
            headerName: "Acccept",
            // filter: true,
            // field: "approve",
            cellRendererFramework: (params) => (
                <center>
                    <div>
                        <Button onClick={()=> acceptProduct(params?.data)} variant="outline-warning">Acccept</Button>
                    </div>
                </center>
            ),
        },
        {
            headerName: "Decline",
            // filter: true,
            // field: "approve",
            cellRendererFramework: (params) => (
                <center>
                    <div>
                        <Button onClick={() => declineProduct(params.data)} variant="outline-danger">Decline</Button>
                    </div>
                </center>
            ),
        },
    ])

    const [rowData, setRowData] = useState([
        // { name: "Hari", shop: "Sri Vinayaka Jewellers", mail: 'hari@gmail.com', date:'22/10/2021' },
        // { name: "Manu", shop: "Sri Vinayaka Jewellers", mail: 'manu@gmail.com', date:'22/10/2021' },
        // { name: "Anu", shop: "Sri Vinayaka Jewellers", mail: 'anu@gmail.com', date:'22/10/2021' }
    ]);

    useEffect(() => {
        productRequestList()
    }, [])

    const productRequestList = async () => {
        try {
            const productList = await AuthServices.getAdmin('/user/productRequests')
            console.log('productList', productList);
            if (productList.error === false) {
                // console.log('VendorList', vendorList);
                setRowData(productList?.data)
            }
        } catch (e) {
            console.log('error', e);
        }
    }

    const acceptProduct = async (data) => {
        try {
            console.log('ddddddddddata', data);
            console.log('ddddddddddataaaaaaa', data?._id);

            const payload = {
                id: data?._id,
                status: 'approved',
            }
            const approveVendor = await AuthServices.patchAdmin('/user/product', payload)
            console.log('approveVendor', approveVendor);
            if (approveVendor.error === false) {
                productRequestList()
            }
        } catch (e) {
            console.log('error', e);
        }
    }

    const declineProduct = async (data) => {
        try {
            console.log('ddddddddddata', data);
            console.log('ddddddddddataaaaaaa', data?._id);

            const payload = {
                id: data?._id,
                status: 'blocked',
            }
            const approveVendor = await AuthServices.patchAdmin('/user/product', payload)
            console.log('approveVendor', approveVendor);
            if (approveVendor.error === false) {
                productRequestList()
            }
        } catch (e) {
            console.log('error', e);
        }
    }

    return (
        <div>
            <h3 className='headertext'>Product Requests</h3>
            <div
                className="ag-theme-alpine"
                style={{ height: "70vh", width: "100%" }}
            >
                <AgGridReact
                    rowHeight={rowHeight}
                    // columnDefs={columns}
                    columnDefs={colDefs}
                    defaultColDef={DefaultColumnSetting}
                    pagination={true}
                    paginationPageSize={10}
                    // onGridReady={onGridReady}
                    rowData={rowData}
                />
            </div>
            <Modal
                show={docmodal}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                centered
                size="lg"
            >
                <h4 className='headertext text-center'>Product Details:</h4>
                <Modal.Body>
                    <Row>
                        <Col md={3}>
                            <Figure>
                                <Figure.Image
                                    width={150}
                                    height={150}
                                    src={prodSelected?.image[0]}
                                />
                            </Figure>
                        </Col>
                        <Col md={3}>
                            <p>Name:</p>
                            <p>Shop Name:</p>
                            <p>Address:</p>
                            <p>Price:</p>
                            <p>Weight:</p>
                            <p>category:</p>
                            <p>Metal:</p>
                            <p>Purity:</p>
                        </Col>
                        <Col md={3}>
                            <p>{prodSelected?.title}</p>
                            <p>{prodSelected?.shops[0]?.name}</p>
                            <p>{prodSelected?.shops[0]?.address?.street || '-'}</p>
                            <p>20,000 /-</p>
                            <p>{prodSelected?.weight} gms</p>
                            <p>{prodSelected?.category}</p>
                            <p>{prodSelected?.metal}</p>
                            <p>{prodSelected?.purity}</p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModal} variant="secondary">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
}

export default ProductRequest