import React, { useEffect, useState } from 'react'
import SideBar from '../../../dashboard/SideBar'
import FirstNavbar from '../../../dashboard/FirstNavbar'
import { Card, Form, Row, Col, Button, Modal, Figure } from 'react-bootstrap'
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import AuthServices from '../../authServices/AuthServices';

const image = require('../../../../assets/imagesCustomer/image.png');

function CustomersBlockedList() {

    const navigate = useNavigate();

    const [docmodal, setDocModal] = useState(false)

    const handleCloseModal = () => setDocModal(false)
    const handleShowModal = () => setDocModal(true)

   

    const rowHeight = 50;
    const DefaultColumnSetting = {
        sortable: true,
        //   filter: true,

        //   floatingFilter: true,
        flex: 1,
        resizable: true,
        minWidth: 120,
    };

    const [colDefs, setColDefs] = useState([
        {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            // filter: true,
            lockPosition: true,
        },
        {
            headerName: "Customer Name",
            // filter: true,
            field: "name",
            valueGetter: 'data?.name',
            cellRendererFramework: (params) => (
                <center>
                    <div onClick={() => handleShowModal()} >{params.data.name} </div>{" "}
                </center>
            ),
        },
        {
            headerName: "Phone",
            // filter: true,
            field: "phone",
            valueGetter: 'data?.phone',
        },
        {
            headerName: "Date blocked",
            // filter: true,
            field: "date",
        },
        // {
        //     headerName: "View",
        //     // filter: true,
        //     // field: "view",
        //     cellRendererFramework: (params) => (
        //         <center>
        //             <FontAwesomeIcon onClick={() => handleShowModal()} className="editIcon" icon={faEye}> </FontAwesomeIcon>{" "}
        //         </center>
        //     ),
        // },
        {
            headerName: "Un-Block",
            // filter: true,
            // field: "approve",
            cellRendererFramework: (params) => (
                <center>
                    <div>
                        <Button onClick={() => acceptVendor(params.data)} variant="outline-warning">un-block</Button>
                    </div>
                </center>
            ),
        },
    ])

    const [rowData, setRowData] = useState([
        // { name: "Hari", shop: "Sri Vinayaka Jewellers", mail: 'hari@gmail.com', date:'22/10/2021' },
        // { name: "Manu", shop: "Sri Vinayaka Jewellers", mail: 'manu@gmail.com', date:'22/10/2021' },
        // { name: "Anu", shop: "Sri Vinayaka Jewellers", mail: 'anu@gmail.com', date:'22/10/2021' }
    ]);

    useEffect(() => {
        customerBlockedList()
    }, [])
    const customerBlockedList = async () => {
        try {
            const vendorList = await AuthServices.getAdmin('/user/all?type=customer&account=blocked')
            // console.log('VendorList', vendorList);
            if (vendorList.error === false) {
                // console.log('VendorList', vendorList);
                setRowData(vendorList?.data)
            }
        } catch (e) {
            console.log('error', e);
        }
    }

    const acceptVendor = async (data) => {
        try {
            console.log('ddddddddddata', data);
            console.log('ddddddddddataaaaaaa', data?._id);

            const payload = {
                _id: data?._id,
                status: 'requested',
            }
            const approveVendor = await AuthServices.patchAdmin('/user/approveOrBlockVendor', payload)
            console.log('approveVendor', approveVendor);
            if (approveVendor.error === false) {
                customerBlockedList()
            }
        } catch (e) {
            console.log('error', e);
        }
    }

    return (
        <div>
            <h3 className='headertext'>Customers Blocked</h3>
            <div
                className="ag-theme-alpine"
                style={{ height: "70vh", width: "100%" }}
            >
                <AgGridReact
                    rowHeight={rowHeight}
                    // columnDefs={columns}
                    columnDefs={colDefs}
                    defaultColDef={DefaultColumnSetting}
                    pagination={true}
                    paginationPageSize={10}
                    // onGridReady={onGridReady}
                    rowData={rowData}
                />
            </div>
            <Modal
                show={docmodal}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                centered
                size="lg"
            >
                <h4 className='headertext text-center'>Customers Detail:</h4>
                <Modal.Body>
                    <Row>
                        <Col md={3}>
                            <Figure>
                                <Figure.Image
                                    width={150}
                                    height={150}
                                    src={image}
                                />
                            </Figure>
                        </Col>
                        <Col md={3}>
                            <p>Name:</p>
                            <p>Phone Number:</p>
                            <p>E-mail Id:</p>
                            <p>Account Number:</p>
                            <p>Bank Name:</p>
                            <p>Ifsc code:</p>
                        </Col>
                        <Col md={3}>
                            <p>Manoj</p>
                            <p>8754678989</p>
                            <p>man@gmail.com</p>
                            <p>231234543456</p>
                            <p>Sbi</p>
                            <p>SBIN00293839</p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModal} variant="secondary">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
}

export default CustomersBlockedList