import React,{useEffect,useState} from 'react'
import SideBar from '../../dashboard/SideBar'
import FirstNavbar from '../../dashboard/FirstNavbar'
import { Card, Form, Row, Col } from 'react-bootstrap'
import Profile from './Profile'
import Investment from './Investment'
import JewelleryPurchased from './JewelleryPurchased'
import CustomerTransactions from './CustomerTransactions'
import { AgGridReact } from "ag-grid-react";
import axios from 'axios';
import { useSearchParams, useLocation } from "react-router-dom";

// const earRing = require('../../../assets/imagesCustomer/earRing1.png')
// const image = require('../../../assets/imagesCustomer/image18.png')

function CustomerData() {
    const [selection, setSelection] = useState('1')
    const [custTransaction,setCustTransaction] = useState()

    const { state } = useLocation();
    console.log('state', state)
    const onDataSelect = (e) => {
        setSelection(e.target.value)
    }
   let [searchParams, setSearchParams] = useSearchParams();
   let id=searchParams.get("id")
    const [profile,setProfile] = useState({})


    let body={id:id}
    useEffect(() => {
        getProfile()
        getTransactions()
    }, []);

    function getProfile(){
        let token=localStorage.getItem('accessToken');

        axios.get(`https://zelt-admin-api.moshimoshi.cloud/user?id=${state?._id}`,{
            headers: {
                "x-access-token": token
            }
        }).then((response) => {
            console.log(response.data.data, "RESPONSE DATA")
            setProfile(response?.data?.data)
            if (response?.data?.error === false) {
                setCustTransaction(response?.data?.data)
            }
        });
    }

    function getTransactions(){
        let token=localStorage.getItem('accessToken');
        axios.get(`https://zelt-admin-api.moshimoshi.cloud/user/customerInvestments?id=${state?._id}`,{
            headers: {
                "x-access-token": token
            }
        },body).then((response) => {
            console.log(response.data.data, "RESPONSE DATA")
            // setProfile(response.data.data)
            if (response.data.error == false) {
            }
        });
    }
    return (
        <div>
            <div class="sidebar">
                <SideBar />
            </div>
            <div class="content">
                <div className="container">
                    <FirstNavbar />
                    <h3 className='headertext'>Customer Data</h3>
                    <div>

                        <Card className="shadow rounded p-2 w-100">
                            <Row>
                                <Col md={3}>
                                    <div className="leftedge d-flex justify-content-space">
                                        <Form.Select
                                            aria-label="Default select example"
                                            size="sm"
                                            //   className="selectsizesmall"
                                            onChange={onDataSelect}
                                        >
                                            {/* <option>Please select Customer Detail</option> */}
                                            <option value="1">Profile</option>
                                            {/* <option value="2">Investments</option> */}
                                            {/* <option value="3">Jewellery Purchased</option> */}
                                            <option value="2">Customer Transaction</option>
                                        </Form.Select>

                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            {selection === '1' && <Profile data={profile} />}
                            {/* {selection === '2' && <Investment/>} */}
                            {/* {selection == 3 && <JewelleryPurchased/>} */}
                            {selection === '2' && <CustomerTransactions custId={state?._id} custTransaction={custTransaction}/>}
                        </Card>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerData