import React, { useState, useEffect } from 'react'
import { Card, Figure, Row, Col, Table, Button } from 'react-bootstrap'
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";

const image = require('../../../assets/imagesCustomer/image.png');

function VendorProfile({ vendor }) {

  const rowHeight = 50;
  const DefaultColumnSetting = {
    sortable: true,
    //   filter: true,

    //   floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };


  const [rowData, setRowData] = useState([]);
  useEffect(() => {
    console.log('vendor?.shops', vendor?.shops);
    setRowData(vendor?.shops)
  }, [])

  const [colDefs, setColDefs] = useState([
    {
      headerName: "#",
      valueGetter: "node.rowIndex + 1",
      // filter: true,
      lockPosition: true,
    },
    {
      headerName: "Store Name",
      // filter: true,
      field: "name",
      valueGetter: 'data?.name',
    },
    {
      headerName: "Store Location",
      // filter: true,
      field: "address",
    },
    {
      headerName: "store Phone",
      // filter: true,
      field: "phone",
    },
    {
      headerName: "store E-mail Id ",
      // filter: true,
      field: "email",
    },
  ])


  return (
    <div>
      <Card className="p-2">
        <h3 className='headertext'>Vendor Profile Details:</h3>
        <Row>
          <Figure>
            <Figure.Image
              width={100}
              height={100}
              // alt="171x180"
              src={vendor?.image}
            />
          </Figure>
        </Row>
        <hr />
        <h4 className='headertext'>Personal Deatils</h4>
        <Row>
          <Col md={3}>
            <h5>Name:</h5>
          </Col>
          <Col md={3}>
            <h5>{vendor?.name}</h5>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <h5>Phone Number:</h5>
          </Col>
          <Col md={3}>
            <h5>+91 {vendor?.phone}</h5>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <h5>E-mail Id :</h5>
          </Col>
          <Col md={3}>
            <h5>{vendor?.email}</h5>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <h5>GST :</h5>
          </Col>
          <Col md={3}>
            <h5>dfkjhsjhfs74657</h5>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <h5>Gender :</h5>
          </Col>
          <Col md={3}>
            <h5>male</h5>
          </Col>
        </Row>
        <hr />
        <h4 className='headertext'>Bank Deatils</h4>
        <Row>
          <Col md={3}>
            <h5>Bank Name:</h5>
          </Col>
          <Col md={3}>
            <h5>SBI</h5>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <h5>Account Number:</h5>
          </Col>
          <Col md={3}>
            <h5>8756476456</h5>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <h5>IFSC Code</h5>
          </Col>
          <Col md={3}>
            <h5>ifsc00076456</h5>
          </Col>
        </Row>
        <hr />
        <h3 className='headertext'>Available stores</h3>
        <div
          className="ag-theme-alpine"
          style={{ height: "70vh", width: "100%" }}
        >
          <AgGridReact
            rowHeight={rowHeight}
            // columnDefs={columns}
            columnDefs={colDefs}
            defaultColDef={DefaultColumnSetting}
            pagination={true}
            paginationPageSize={10}
            // onGridReady={onGridReady}
            rowData={rowData}
          // onRowClicked={(e) => 
          //     navigate('/vendorData',{id:e.data._id})}
          />
        </div>
      </Card>
    </div>
  )
}

export default VendorProfile